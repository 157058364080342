@import "variables";
@import "~bootstrap/scss/bootstrap";

@font-face {
  font-family: "Montserrat";
  src: local("Montserrat"),
    url(./fonts/Montserrat-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Montserrat";
  src: local("Montserrat"), url(./fonts/Montserrat-Bold.ttf) format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "Montserrat";
  src: local("Montserrat"),
    url(./fonts/Montserrat-Italic.ttf) format("truetype");
  font-style: italic;
}

body {
  margin: 0;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $black;
  background-color: #f3f3f3;
}

html {
  scroll-behavior: smooth;
}

@media (prefers-reduced-motion: reduce) {
  html {
    scroll-behavior: auto;
  }
}
